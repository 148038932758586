import React, { useEffect } from "react";
import { Drawer, DepositComponent } from "../index.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, NestedModal } from "../index.js";
import { useGlobalContext } from "../../context.js";
import localData from "../../localData.js";
import { auth } from "../../config/firebase.js";

export default function Navbar({ title = "", titlePopup = "" }) {
    const { avatar, user } = localData.images;
    const {
        showSidebar,
        setShowSidebar,
        successAlert,
        showProfileDrawer,
        setShowProfileDrawer,
        showDepositModal,
        setShowDepositModal,
        requestedData,
        requestedData: { userProfileData },
        isLoading: { userProfileIsLoading },
        userData,
        navbarRef,
        formatNumber,
        isDarkModeEnabled,
        setIsDarkModeEnabled,
        handleSignOut,
        balance,
    } = useGlobalContext();
    const { arrowDown, bars, barsClose, sun, moon, deposit } = localData.svgs;
    const { textPreloader } = localData.images;
    var parsedUser;

    const loggedInUser = localStorage.getItem("user");

    parsedUser = JSON.parse(loggedInUser);

    // DRAWER
    const DrawerComponent = ({ callbackFromParent }) => {
        const { logout } = localData.svgs;
        const { isLoading } = useGlobalContext();

        return (
            <>
                {/* <p>drawer with callback</p> */}
                {/* <Button variant="contained" name="close drawer" onClick={callbackFromParent} /> */}
                <div className="drawer-header">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/web3-marketing-hub.appspot.com/o/images%2Fuser.png?alt=media&token=55bb1159-4dc8-4a03-abf4-b2ba80ed52f9"
                        alt=""
                    />
                    <p className="drawer-text">
                        Welcome to Blockchain-Ads
                        <br />{" "}
                        <strong>
                            {userProfileIsLoading ? (
                                <img src={textPreloader} className="text-preloader" />
                            ) : (
                                userProfileData.contact_name
                            )}
                        </strong>
                    </p>
                </div>

                <div className="drawer-body">
                    <Link to="/profile">
                        <Button name="My Profile" color="dark" onClick={callbackFromParent} />
                    </Link>
                    <br />

                    {/* <Button
                        name="deposit funds"
                        color="dark"
                        onClick={() => {
                            // callbackFromParent()
                            setShowDepositModal("block");
                        }}
                    />
                    <br /> */}
                    {/* <div className="on-hold" > */}
                    {/* <Link to="/payouts">
                        <Button name="Payouts" color="dark" onClick={callbackFromParent} />
                    </Link> */}
                    {/* </div> */}
                    <br />
                    <br />

                    <Button
                        name="log out"
                        color="danger"
                        variant="outlined"
                        startIcon={logout}
                        onClick={() => {
                            callbackFromParent();
                            setTimeout(() => {
                                handleSignOut();
                            }, 1000);
                        }}
                    />
                </div>

                <div className="drawer-footer">
                    <div className="wrapper">
                        <Button
                            icon={sun}
                            // size="lg"
                            variant="text"
                            color="success"
                            className={`mode ${isDarkModeEnabled ? "" : "active"}`}
                            onClick={() => {
                                setIsDarkModeEnabled(false);
                                document.body.classList.remove("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", false);
                            }}
                        />
                        <Button
                            icon={moon}
                            // size="lg"
                            variant="contained"
                            color="dark"
                            className={`mode ${isDarkModeEnabled ? "active" : ""}`}
                            onClick={() => {
                                setIsDarkModeEnabled(true);
                                document.body.classList.add("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", true);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg" ref={navbarRef}>
                <h1 className="navbar-title navbar-title-sm-media" title={titlePopup}>
                    {title}
                </h1>
                <div className="wrapper">
                    <Button
                        className="sidebar-toggler-show"
                        size="sm"
                        icon={showSidebar ? bars : barsClose}
                        variant="circle"
                        color="dark"
                        onClick={() => setShowSidebar(!showSidebar)}
                    />
                    <h1 className="navbar-title" title={titlePopup}>
                        {title}
                    </h1>
                </div>

                <div className="wrapper">
                    <Modal
                        Child={DepositComponent}
                        className="deposit-modal modal-dialog-centered"
                        buttonTitle="Withdrawal"
                        buttonStartIcon={deposit}
                        buttonClassName={`rounded-pill deposit-btn ${
                            window.innerWidth < 576 ? "btn-sm" : ""
                        }`}
                        buttonColor="primary"
                        display={showDepositModal}
                        setDisplay={setShowDepositModal}
                        togglerInfo={
                            <>
                                Top up your account balance to use <br /> Blockchain-Ads.
                            </>
                        }
                        // togglerId="tooltip-deposit-btn"
                    ></Modal>
                    <div className="budget dark-mode-style">
                        <p className="budget-title">BALANCE</p>
                        <div className="budget-balance">
                            $
                            {parseFloat(requestedData?.userProfileData?.currentOrganization?.balance) > 0
                                ? formatNumber(parseFloat(requestedData?.userProfileData?.currentOrganization?.balance)?.toFixed(0) || "0")
                                : 0}
                        </div>
                    </div>
                    <Drawer
                        Component={DrawerComponent}
                        className="drawer-right"
                        display={showProfileDrawer}
                        setDisplay={setShowProfileDrawer}
                        disabled={userProfileIsLoading}
                        toggler={
                            <Button color="dark" variant="text">
                                <div className="profile  dark-mode-style">
                                    <div className="profile-image">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/web3-marketing-hub.appspot.com/o/images%2Fuser.png?alt=media&token=55bb1159-4dc8-4a03-abf4-b2ba80ed52f9"
                                            alt=""
                                        />
                                    </div>
                                    <div className="profile-name">
                                        {userData.isLoading ? (
                                            <img src={textPreloader} className="text-preloader" />
                                        ) : (
                                            userData.data.contact_name
                                        )}
                                    </div>
                                    <div className="profile-icon">{arrowDown}</div>
                                </div>
                            </Button>
                        }
                    />
                </div>

                {/* <div className="navbar-collapse" id="navbarSupportedContent">
                     <ul className="navbar-nav me-auto">
                         {menu.map(({ title, to }, index) => (
                             <li className="nav-item" key={index}>
                                 <Link to={to} className={`nav-link ${to === location.pathname ? "active" : ""}`}>
                                     {title}
                                 </Link>
                             </li>
                         ))}
                        
                     </ul>
                 </div> */}
            </nav>
            <hr className="navbar-divider" />
        </>
    );
}
