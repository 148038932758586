import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Select,
  Autocomplete,
  Button,
  Field,
  NestedModal,
  Checkbox,
} from "..";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../../hooks/useFetch";
import { auth } from "../../config/firebase.js";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter.jsx";
import useGSheetDB from "../../pages/referral/useGSheetDB.jsx";
import { isAddress } from "ethers";

export default function DepositComponent({ setIsOpen }) {
  const {
    errorAlert,
    requestedData,
    // requestedData: {
    //     userProfileData: {
    //         currentOrganization: { balance, PredictedBalance },
    //     },
    // },
    formatNumber,
    isDarkModeEnabled,
    balance,
    userData,
    refreshGSpreadSheetDB,
    setrefreshGSpreadSheetDB,
    createPublisherWithdrawal,
    getPublisherWithdrawals,
  } = useGlobalContext();

  const { addCommas } = useFormatter();

  const { preloaderBig, pay, plants } = localData.images;
  const { close, wallet, budget } = localData.svgs;
  const [isLoading, setIsLoading] = useState();
  const {
    getAllCurrencies,
    getPaymentWithInvoice,
    paymentWithInvoice,
    getFunds,
  } = useFetch();

  const [state, setState] = useState({
    priceAmount: parseFloat(
      requestedData?.userProfileData?.currentOrganization?.balance || 0
    )?.toFixed(0),
    address: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    if (name === "address") {
      validateAddress(value);
    }
    if (name === "priceAmount") {
      validateAmount(parseFloat(value));
    }
    setState({ ...state, [name]: value });
  };

  const onInput = (e) => {
    if (
      parseFloat(e.target.value) >
      parseFloat(
        requestedData?.userProfileData?.currentOrganization?.balance || 0
      )?.toFixed(0)
    ) {
      e.target.value = parseFloat(
        requestedData?.userProfileData?.currentOrganization?.balance || 0
      )?.toFixed(0);
    }
  };
  const [isValid, setIsValid] = useState(null); // null means no validation yet
  const [isAmountValid, setIsAmountValid] = useState(true); // null means no validation yet
  const [amountError, setAmountError] = useState(null); // null means no validation yet

  const validateAddress = (input) => {
    // Use ethers.js to validate the address
    if (isAddress(input)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const validateAmount = (input) => {
    console.log(typeof input);
    if (input < 10) {
      console.log("yeah");
      setAmountError("Amount must be greateer than 10");
      setIsAmountValid(false);
    } else if (
      input > requestedData?.userProfileData?.currentOrganization?.balance
    ) {
      setAmountError("Amount cant be above available balance");
      setIsAmountValid(false);
    } else {
      setAmountError(null);
      setIsAmountValid(true);
    }
  };

  const [url, setURL] = useState("");
  const [display, setDisplay] = useState("none");

  const NestedModalChildren = () => {
    const [isChecked, setIsChecked] = useState(false);
    const { isDarkModeEnabled } = useGlobalContext();

    const handleCheckbox = (target) => {
      setIsChecked(target);
    };

    return (
      <div className="redirect-modal">
        <div className="nested-modal-header">
          <h5 className="nested-modal-title" id="exampleModalLabel">
            Payment Invoice
          </h5>
        </div>
        <div className="nested-modal-body">
          <p className="text info-text">You are about to be redirected</p>

          <p className="text">
            Important points to note before depositing funds for your campaign:
          </p>
          <br />

          <ul>
            <li>
              <span className="list-style list-style-primary"></span>
              <p>
                Blockchain-Ads and/or the publishers may reject any ads at any
                moment.
              </p>
            </li>
            <li>
              <span className="list-style list-style-primary"></span>
              <p>
                Any refunds are at the entire discretion of Blockchain-Ads with
                a minimum 20% fee to be retained.
              </p>
            </li>
          </ul>

          <Checkbox
            callback={handleCheckbox}
            checked={isChecked}
            color="primary"
            label="I understand and I want to proceed"
            size="sm"
          />
        </div>
        <div className="nested-modal-footer">
          <Button
            variant="contained"
            color="primary"
            size="sm"
            disabled={!isChecked}
          >
            <a href={url} target="_blank">
              Submit
            </a>
          </Button>
        </div>
      </div>
    );
  };

  const handleSubmit = async () => {
    handleWalletSubmit();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isBalanceOpen, setIsBalanceOpen] = useState(false);

  const { getData, getSingleData, addData, updateData } = useGSheetDB();

  const [storedUser, setStoredUser] = useState({});
  const [matchingUser, setMatchingUser] = useState({});
  const [convertedBalance, setConvertedBalance] = useState(0);
  // const [isLoading,setIsLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, [isLoading]);

  const handleWalletSubmit = async () => {
    closeModal();
    const res = await createPublisherWithdrawal({
      priceAmount: state.priceAmount,
      address: state.address,
    });
  };
  return (
    <div className="deposit-component">
      <div className="modal-header">
        <h2 className="modal-title">Withdraw your payout</h2>

        <Button
          className="btn-close"
          variant="circle"
          color="dark"
          size="sm"
          icon={close}
          onClick={closeModal}
        />
      </div>

      <div className="modal-body">
        {/* <img className="modal-image" src={pay} alt="" /> */}
        {/* <Dropdown
                    {...{
                        title: "Balance",
                        variant: "contained",
                        color: "light",
                        className: "balance-dropdown",
                        startIcon: wallet,
                        isOpen: isBalanceOpen,
                        setIsOpen: setIsBalanceOpen,
                    }}
                >
                    <div className="balance-amount">
                    $
                            {parseFloat(requestedData?.userProfileData?.currentOrganization?.balance) > 0
                                ? formatNumber(parseFloat(requestedData?.userProfileData?.currentOrganization?.balance)?.toFixed(0) || "0")
                                : 0}
                    </div>
                    <p className="balance-text">Available Balance</p>
                </Dropdown> */}

        <p className="balance-info">
          Choose your preferred currency for your payout.
        </p>
        <img className="modal-cover" src={plants} alt="" />
        <Field
          type="text"
          label="Wallet Address"
          color="secondary"
          placeholder=""
          value={state.address}
          required={true}
          name="address"
          size="sm"
          errorMessage={
            !isValid ? "Address must be a valid BSC address (USDT)" : ""
          }
          callback={handleOnChange}
          onInput={onInput}
        />
        <Field
          type="number"
          label="Amount"
          color="secondary"
          placeholder=""
          value={state.priceAmount}
          max={parseFloat(
            requestedData?.userProfileData?.currentOrganization?.balance || 0
          )?.toFixed(0)}
          required={true}
          errorMessage={!isAmountValid ? amountError : ""}
          name="priceAmount"
          size="sm"
          callback={handleOnChange}
          startIcon={budget}
          onInput={onInput}
        />
        <div
          data-tooltip-id="deposit-btn"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            "Please deposit a minimum amount of 10000."
          )}
        >
          <Button
            variant="contained"
            name="Submit"
            color={`${isDarkModeEnabled ? "light" : "dark"}`}
            onClick={handleSubmit}
            icon={
              isLoading ? (
                <span className="endIcon">
                  <img src={preloaderBig} />
                </span>
              ) : null
            }
            disabled={
              isLoading ||
              state.priceAmount <= 0 ||
              state.address.length == 0 ||
              !isValid ||
              !isAmountValid
            }
            className="balance-btn"
            // tooltipContent={state.priceAmount < 10000 ? "Please deposit a minimum amount of 10000.": ''}
          />
          {/* {state.priceAmount < 0 && <Tooltip id="deposit-btn" className="custom-tooltip" />} */}
        </div>
        {/* <Autocomplete
                    {...{
                        items: items,
                        setItems: setItems,
                        placeholder: "loading...",
                        variant: "outlined",
                        color: "secondary",
                        label: "Currency:",
                        className: "modal-autocomplete",
                    }}
                /> */}
      </div>
      <NestedModal
        togglerClassName="nested-modal-toggler"
        className="modal-dialog-centered redirect-modal"
        display={display}
        setDisplay={setDisplay}
      >
        <NestedModalChildren />
      </NestedModal>
      <div className="modal-footer"></div>
    </div>
  );
}
