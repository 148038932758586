import React, { useState, useEffect } from "react";
import { Navbar, TableSkeleton, Button, Pagination } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import TrackingDrawer from "../tracking/tracking-drawer/TrackingDrawer";
import useFormatter from "../../hooks/useFormatter";
import ConfirmationDialog from "../../components/confirmationDialog";

const { textPreloader, trashV2 } = localData.svgs;

export default function Payouts() {
  const { formatDate, formatCurrency, capitalizeFirstLetter } = useFormatter();

  const { fade } = useGlobalContext().animations;
  const { getPublisherWithdrawals, deletePublisherWithdrawal, successAlert } =
    useGlobalContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [clickedId, setClickedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const handleConfirm = async () => {
    await deletePublisherWithdrawal(clickedId);
    const result = await getPublisherWithdrawals();
    const sortedData = sortAndPaginate(result);
    // Set the first 10 items initially
    setItems(sortedData);
    handleCloseDialog();
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setClickedId(null);
  };
  const handlePagination = (nextPage = 1) => {
    setPage(nextPage);

    const startIndex = (nextPage - 1) * 10;
    const endIndex = startIndex + 10;
    setWithdrawals(data.slice(startIndex, endIndex));
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await getPublisherWithdrawals();
        const sortedData = sortAndPaginate(result);
        // Set the first 10 items initially
        setItems(sortedData);
      } catch (error) {
        console.error("Error fetching publisher payouts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty depend

  const sortAndPaginate = (data) => {
    const sortedData = data.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    // Set the sorted data
    setData(sortedData);
    setTotalPages(Math.ceil(sortedData.length / 10));
    return sortedData;
  };

  const setItems = (data) => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    setWithdrawals(data.slice(startIndex, endIndex));
    console.log(data)
    console.log(withdrawals)
  };

  return (
    <motion.main {...fade} className="main tracking-page">
      <Navbar title="Withdrawals" />
      <br />

      <TrackingDrawer />

      {isLoading ? (
        <TableSkeleton message="Loading..." icon={textPreloader} />
      ) : (
        <>
          <div className="scroll shadow">
            <table className="conversion-table">
              <thead>
                <tr>
                  <th className="text-start">Amount</th>
                  <th>Status</th>
                  <th className="text-start">Withdrawal Address</th>
                  <th className="text-start">TX Hash</th>
                  <th>Date created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!Array.isArray(withdrawals) ||
                withdrawals.length === 0 ? (
                  <tr style={{ pointerEvents: "none" }}>
                    <td colSpan="10">
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  withdrawals.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td className="">{formatCurrency(item.amount)}</td>
                        <td className="text-center">
                          <span className={`status ${item.payment_status}`}>
                            {capitalizeFirstLetter(item.payment_status)}
                          </span>
                        </td>
                        <td>
                          {item.wallet_address}
                        </td>
                        <td>
                          {item.txid}
                        </td>
                        <td className="text-center">
                          {formatDate(new Date(item.created_at))}
                        </td>
                        <td className="text-center">
                          {item.payment_status === "pending" ? (
                            <Button
                              variant="circle"
                              icon={trashV2}
                              color="danger"
                              size="sm"
                              onClick={() => {
                                setIsDialogOpen(true);
                                setClickedId(item.id);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
            <ConfirmationDialog
              isOpen={isDialogOpen}
              onClose={handleCloseDialog}
              onConfirm={handleConfirm}
              message="Are you sure you want to proceed?"
            />
          </div>
          {!withdrawals.length ? (
            ""
          ) : (
            <div className="pagination-wrapper">
              <Pagination
                callback={handlePagination}
                currentPage={page}
                totalPages={totalPages}
                isLoading={isLoading}
              />
            </div>
          )}
          <br />
          <br />
          <br />
        </>
      )}
    </motion.main>
  );
}
